var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var naiveFallback = function () {
  if (typeof self === "object" && self) return self;
  if (typeof window === "object" && window) return window;
  throw new Error("Unable to resolve global `this`");
};

exports = function () {
  if (this || _global) return this || _global; // Unexpected strict mode (may happen if e.g. bundled into ESM module)
  // Thanks @mathiasbynens -> https://mathiasbynens.be/notes/globalthis
  // In all ES5+ engines global object inherits from Object.prototype
  // (if you approached one that doesn't please report)

  try {
    Object.defineProperty(Object.prototype, "__global__", {
      get: function () {
        return this || _global;
      },
      configurable: true
    });
  } catch (error) {
    // Unfortunate case of Object.prototype being sealed (via preventExtensions, seal or freeze)
    return naiveFallback();
  }

  try {
    // Safari case (window.__global__ is resolved with global context, but __global__ does not)
    if (!__global__) return naiveFallback();
    return __global__;
  } finally {
    delete Object.prototype.__global__;
  }
}();

export default exports;